<!-- eslint-disable vue/no-v-html -->
<template>
  <div className="banner">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <router-link v-if="link" :to="link" class="nav-link" style="padding-left: 0px !important"
            ><font-awesome-icon :icon="linkIcon" class="icon" /> {{ linkText }}</router-link
          >
          <h1>{{ title }}</h1>
          <p v-html="text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    link: {
      type: String,
      default: '',
    },
    linkIcon: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
