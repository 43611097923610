export const formatStreetName = function formatStreetName(address) {
  address = address.split(', ');
  if (address[0] && address[1]) {
    return `${address[0]} ${address[1]}`;
  }
  return null;
};

export const formatAddressWithoutStreetName = (address) =>
  address.split(', ').slice(2).join('<br />');
export const formatAddress = (address) =>
  `${formatStreetName(address)} <br /> ${formatAddressWithoutStreetName(address)}`;
